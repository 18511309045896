/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment';
// ICONS
import { FaXmark } from 'react-icons/fa6';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import * as meetingService from '../../../../services/management/meetingService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const initialMeetingModel = {
  id: 0,
  isActive: true,
  startTime: '',
  endTime: '',
  description: 'For details on our upcoming board meeting, including the agenda and how to participate, reach out to us at (225) 791-6509 or lpgdd2@outlook.com.',
  location: '8639 Springfield Rd, Watson, LA, 70786',
};

const MeetingManagementPage = (props) => {
  const { history, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { meetingId } = useParams();
  const [meeting, setMeeting] = useState([]);
  // FORM STATE MANAGEMENT
  const [minutesFile, setMinutesFile] = useState([]);
  const [agendasFile, setAgendasFile] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [hasMinutesFilePath, setHasMinutesFilePath] = useState('');
  const [hasAgendasFilePath, setHasAgendasFilePath] = useState('');

  const timeInputOptions = {
    enableTime: true,
    noCalendar: true,
    time_24hr: false,
    altInput: true,
    altFormat: 'h:i K',
    dateFormat: 'h:i K',
    minTime: '05:00',
    maxTime: '22:00',
    minuteIncrement: 10,
  };

  const dateInputOptions = {
    altInput: true,
    altFormat: 'F j, Y',
  };

  useEffect(() => {
    if (meetingId) {
      fetchMeeting();
    }
  }, []);

  const formik = useFormik({
    initialValues: meetingId ? meeting : initialMeetingModel,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      startTime: Yup.string().nullable(),
      endTime: Yup.string().nullable(),
      description: Yup.string(),
      location: Yup.string(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const model = {
        ...values,
        date: selectedDate,
        minutesFile: minutesFile.length > 0 ? minutesFile?.[0]?.file : '',
        minutesFilePath: hasMinutesFilePath,
        agendasFile: agendasFile.length > 0 ? agendasFile?.[0]?.file : '',
        agendasFilePath: hasAgendasFilePath,
      };

      if (meetingId) {
        updateMeeting(model);
      } else {
        createMeeting(model);
      }
    },
  });

  // FETCH MEETING
  const fetchMeeting = () => {
    setIsLoading(true);
    meetingService.getMeetingById(meetingId).then((res) => {
      setMeeting(res);
      setSelectedDate(res.date || '');
      setHasMinutesFilePath(res.minutesFilePath || '');
      setHasAgendasFilePath(res.agendasFilePath || '');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  // UPDATE MEETING
  const updateMeeting = (model) => {
    setIsLoading(true);
    meetingService.updateMeeting(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/meetings');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  // CREATE MEETING
  const createMeeting = (model) => {
    setIsLoading(true);
    meetingService.createMeeting(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/meetings');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock className="content-container--card-style mb-120" onSubmit={formik.handleSubmit}>

              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={meetingId ? 'Edit Meeting' : 'New Meeting'}
                    headerSize="lg"
                    className="mb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Meeting Details"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr"
                  />
                </ContentBlock>

                {/* MEETING DETAILS */}
                <Section isFullWidth className="pl-0 pr-0 mb-20">
                  {/* DATE */}
                  <ContentBlock cols={2}>
                    <InputBlock
                      required
                      type="date"
                      label="Meeting Date"
                      placeholder="Set Date"
                      value={meeting.date || ''}
                      options={dateInputOptions}
                      onChange={(e) => setSelectedDate(e.length > 0 && moment(e[0]).toISOString())}
                    />
                  </ContentBlock>

                  {/* START TIME */}
                  <ContentBlock cols={2}>
                    <InputBlock
                      type="time"
                      label="Start Time"
                      placeholder="Set Start Time"
                      value={formik.values.startTime}
                      options={timeInputOptions}
                      onChange={(e) => {
                        formik.setFieldValue('startTime', e.target.value);
                      }}
                    />
                  </ContentBlock>

                  {/* END TIME */}
                  <ContentBlock cols={2}>
                    <InputBlock
                      type="time"
                      label="End Time"
                      placeholder="Set End Time"
                      value={formik.values.endTime}
                      options={timeInputOptions}
                      onChange={(e) => {
                        formik.setFieldValue('endTime', e.target.value);
                      }}
                    />
                  </ContentBlock>
                </Section>

                {/* DESCRIPTION */}
                <Section isFullWidth className="pl-0">
                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      label="Description"
                      placeholder="Provide a brief description of the meeting here..."
                      errorMessage={formik.errors.description}
                      inputState={`${helper.getInputClasses(formik, 'description')}`}
                      {...formik.getFieldProps('description')}
                    />
                  </ContentBlock>
                </Section>

                {/* LOCATION */}
                <Section isFullWidth className="pl-0">
                  <ContentBlock cols={6}>
                    <InputBlock
                      // isRequired
                      label="Location"
                      placeholder="123 Main Street"
                      errorMessage={formik.errors.location}
                      inputState={`${helper.getInputClasses(formik, 'location')}`}
                      {...formik.getFieldProps('location')}
                    />
                  </ContentBlock>
                </Section>

                {/* IS MEETING ACTIVE */}
                <Section isFullWidth className="pl-0 mt-20">
                  <ContentBlock cols={3}>
                    <CheckboxBlock
                      containerClassName="pl-0 mb-30"
                      label="Is Meeting Active?"
                      id="isActive"
                      {...formik.getFieldProps('isActive')}
                      isChecked={formik.values.isActive}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30 mb-80"
              >
                {/* MINUTES UPLOAD */}
                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Minutes Document"
                    subtitle="Upload meeting minutes document."
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={10} className="mt-15">
                    {!meetingId ? (
                      <FileUploadBlock onupdatefiles={(files) => setMinutesFile(files)} />
                    ) : (
                      <>
                        {hasMinutesFilePath ? (
                          <CustomBlock
                            className="flex-start gap-10 light--sbg"
                            style={{
                              width: 'fit-content',
                              padding: '10px',
                              borderRadius: '6px',
                              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                            }}
                          >
                            <FaXmark
                              size="26"
                              className="light--clr p-1"
                              onClick={() => setHasMinutesFilePath('')}
                              style={{ backgroundColor: 'red', borderRadius: '6px', cursor: 'pointer' }}
                            />
                            <p className="dark--clr fw-500">Minutes Document ({`${moment(meeting.date).format('MMMM D, YYYY')}`})</p>
                          </CustomBlock>
                        ) : (
                          <FileUploadBlock
                            onupdatefiles={(files) => setMinutesFile(files)}
                          />
                        )}
                      </>
                    )}
                  </ContentBlock>
                </ContentBlock>

                {/* AGENDA UPLOAD */}
                <ContentBlock cols={6}>
                  <ContentHeader
                    title="Agenda Document"
                    subtitle="Upload an agenda for this meeting."
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={10} className="mt-15">
                    {!meetingId ? (
                      <FileUploadBlock onupdatefiles={(files) => setAgendasFile(files)} />
                    ) : (
                      <>
                        {hasAgendasFilePath ? (
                          <CustomBlock
                            className="flex-start gap-10 light--sbg"
                            style={{
                              width: 'fit-content',
                              padding: '10px',
                              borderRadius: '6px',
                              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                            }}
                          >
                            <FaXmark
                              size="26"
                              className="light--clr p-1"
                              onClick={() => setHasAgendasFilePath('')}
                              style={{ backgroundColor: 'red', borderRadius: '6px', cursor: 'pointer' }}
                            />
                            <p className="dark--clr fw-500">Agenda Document ({`${moment(meeting.date).format('MMMM D, YYYY')}`})</p>
                          </CustomBlock>
                        ) : (
                          <FileUploadBlock
                            onupdatefiles={(files) => setAgendasFile(files)}
                          />
                        )}
                      </>
                    )}
                  </ContentBlock>
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={meetingId > 0 ? 'Save Changes' : 'Create Meeting'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/admin/management/meetings"
                optionalActionText={meetingId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/admin/management/meetings"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
  }
)(MeetingManagementPage);